    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include mobile-only {
        background: $color-base-50;
    }
}

.menu {
    @include tablet-up {
        max-height: 50vh;
        overflow: auto;
    }
}

.title {
    display: block;
    color: $color-base-600;
    font-weight: 500;
}
