    @import "styles/variables";
    @import "styles/mixins";

$story-container-max-width: 926px;

.story {
    padding: $spacing-8 $spacing-3;
}

.mainImage {
    @include mobile-only {
        @include full-width;
    }

    @include tablet-up {
        margin: $spacing-6 auto;
    }

    @include desktop-up {
        margin: $spacing-8 auto;
        max-width: $story-container-max-width;
    }
}

.container {
    margin: 0 auto;
    padding: $spacing-6 0;

    @include desktop-up {
        max-width: $story-container-max-width;
        padding: $spacing-9;
    }

    &.withImage {
        padding-top: $spacing-5;

        @include desktop-up {
            padding-top: 0;
        }
    }
}

.meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre;
    margin-bottom: $spacing-4;
}

.separator {
    width: 100%;
    height: 1px;
    margin: $spacing-7 auto;
    background: $color-base-200;
}

.categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.categoryLink {
    background-color: var(--prezly-accent-color);
    color: $color-base-white;
    margin-bottom: $spacing-1;
}

.date {
    @include text-small;

    margin: $spacing-4 0;
    color: $color-base-400;
    text-transform: uppercase;
}
