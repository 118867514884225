    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    align-items: center;
    margin-right: auto;

    @include mobile-only {
        display: none;
    }
}

.link {
    font-size: $font-size-xs;
    color: var(--prezly-header-link-color);

    &:first-child {
        margin-left: -$spacing-3;
    }
}

.more {
    font-size: $font-size-xs;
    color: var(--prezly-header-link-color);
}

.dropdown {
    top: 50px;
    z-index: 10;
}
