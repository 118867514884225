    @import "styles/variables";
    @import "styles/mixins";

.link {
    @include text-small;
    @include link-dark;

    padding: $spacing-small $spacing-1;
    background-color: #f1f5f9;
    border-radius: $border-radius-m;
    font-weight: $font-weight-bold;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        span {
            text-decoration: underline;
        }
    }

    &:not(:last-child) {
        margin-right: $spacing-half;
    }
}
