    @import "styles/variables";
    @import "styles/mixins";

.subtitle {
    @include paragraph;

    margin: 0 0 $spacing-4;
    color: $color-base-600;
    font-weight: $font-weight-regular;

    mark {
        @include highlighted-text;
    }
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}
