    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: fixed;
    bottom: $spacing-9;
    right: $spacing-9;
    display: flex;
    flex-direction: column;
    margin: $spacing-6 0;
    z-index: 10;

    @include mobile-only {
        position: static;
        flex-direction: row;
    }
}

.button {
    @include border-radius-m;

    font-size: $font-size-xs;
    border: 1px solid $color-borders;
    background: $color-base-white;
    height: $spacing-6;
    width: $spacing-6;
    align-items: center;
    justify-content: center;

    @include mobile-only {
        &:not(:last-child) {
            margin-right: $spacing-3;
        }
    }

    &:not(:last-child) {
        margin-bottom: $spacing-3;
    }

    &:hover,
    &:focus {
        border-color: $color-base-400;
    }
}

.icon {
    width: $spacing-3;
    height: $spacing-3;
    margin-top: $spacing-half;
}

.scrollToTop {
    position: static !important;
    margin-bottom: $spacing-3;
}
