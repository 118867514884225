    @import "styles/variables";
    @import "styles/mixins";

.highlightedStoriesContainer {
    margin-bottom: $spacing-8;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: $spacing-6 $spacing-6;

    @include tablet-only {
        grid-template-columns: 1fr;
    }

    @include mobile-only {
        grid-template-columns: 1fr;
    }
}

.storiesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-6 $spacing-6;

    @include tablet-only {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mobile-only {
        padding-right: $grid-spacing-mobile;
        padding-left: $grid-spacing-mobile;
        grid-template-columns: 1fr;
    }
}

.noStories {
    padding: $spacing-10 0;
    margin: 0 auto;
    text-align: center;

    @include desktop-up {
        padding: 11rem 0;
    }
}

.noStoriesTitle {
    margin-top: $spacing-4;
    margin-bottom: $spacing-2;
}

.noStoriesSubtitle {
    color: $color-base-500;
    margin: 0;
}
