    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;
    height: 450px;
    max-width: 730px;
    width: 100%;
    border-radius: $border-radius-lg;

    @include mobile-only {
        width: calc(100% - 2 * $grid-spacing-mobile);
        margin-right: $grid-spacing-mobile;
        margin-left: $grid-spacing-mobile;
    }
}

.overlay {
    position: inherit;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-lg;
    /* stylelint-disable color-function-notation */
    background-image: linear-gradient(
        180deg,
        rgba(39, 39, 42, 0%) 0%,
        rgba(22, 22, 23, 10%) 20%,
        rgba(0, 0, 0, 40%) 80%
    );
    /* stylelint-enable color-function-notation */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: $spacing-5;

    @include mobile-only {
        padding: $spacing-3;
    }
}

.imageWrapper {
    position: absolute;
    inset: 0;
    border-radius: $border-radius-lg;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
}

.placeholder {
    background-color: $color-placeholder-bg;
}

.title {
    @include heading-1;

    margin-top: $spacing-3;
}

.titleLink {
    text-decoration: none;
    color: $color-base-white;

    &:hover,
    &:focus {
        color: $color-base-200;
    }
}

.date {
    color: $color-base-white;
}
