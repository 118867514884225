    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding: $spacing-9 0 0;
    color: $color-base-white;
    background: $color-base-800;
}

.columns {
    display: grid;
    gap: $spacing-4;

    @include desktop-up {
        grid-template-columns: 2fr 1fr 1fr;
    }
}

.heading {
    @include paragraph;

    font-weight: 700;
    margin: $spacing-3 0;
    color: $color-base-white;
}

.aboutUs {
    @include text-small;

    @include tablet-up {
        grid-column: span 2 / span 2;
    }

    @include desktop-up {
        grid-column: span 1 / span 1;

        + .contacts {
            margin-left: $spacing-6;
        }
    }

    p {
        @include text-small;
    }
}

.about {
    * {
        color: inherit;
    }

    *:empty {
        display: none;
    }
}

.socialMedia {
    margin-top: $spacing-6;

    @include mobile-only {
        margin-top: $spacing-2;
    }
}

.linkWrapper {
    display: flex;
    align-items: center;

    p {
        font-size: $font-size-xs;
        margin: $spacing-half;
    }
}

.icon {
    height: 14px;
    width: 14px;
    margin-right: $spacing-1;
}

.link,
.about a {
    @include link-white;

    text-decoration: none;
    font-size: $font-size-xs;
}

.separator {
    width: 1.5rem;
    height: 0.125rem;
    background: $color-base-white;
}
