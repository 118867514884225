    @import "styles/variables";
    @import "styles/mixins";

.title {
    @include heading-1;

    margin: $spacing-4 0;
    color: $color-base-800;

    mark {
        @include highlighted-text;
    }
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}
