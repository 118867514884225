    @import "styles/variables";
    @import "styles/mixins";

$story-card-height: 450px;
$story-image-height: 180px;
$story-content-height: 270px;
$title-color: #27446e;

.container {
    position: relative;
    height: $story-card-height;
    border: 1px solid $color-borders;
    background-color: $color-base-white;
    border-radius: $border-radius-lg;
}

.imageWrapper {
    display: block;
    overflow: hidden;
    text-decoration: none;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;

    @include desktop-up {
        .image {
            transform: scale(1);
            transition: transform 0.5s ease-in-out;
        }

        &:hover .image {
            transform: scale(1.05);
        }
    }
}

.placeholder,
.image {
    height: $story-image-height;
}

.content {
    padding: $spacing-3 $spacing-3;
    height: $story-content-height;
    display: flex;
    flex-direction: column;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-1;
}

.title {
    @include heading-2;
    @include ensure-max-text-height(2, $line-height-l);

    color: $title-color;
    font-weight: $font-weight-bold;
    margin-top: 0;
    margin-bottom: $spacing-2;
}

.titleLink {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
        color: $color-base-500;
    }
}

.subtitle {
    @include text-label;
    @include ensure-max-text-height(2, $line-height-m);

    margin: 0 0 $spacing-2;
    font-weight: $font-weight-regular;
    color: $color-base-600;
    text-align: left;
}

.date {
    @include text-small;

    margin-top: auto;
    color: $color-base-400;
    text-transform: uppercase;
}
