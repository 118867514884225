    @import "styles/variables";
    @import "styles/mixins";

.container {
    background-image: url("/images/header-bg.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 3rem 0;

    @include mobile-only {
        display: none;
    }
}

.inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: center;

    @include desktop-large-up {
        grid-template-columns: 450px 1fr;
    }
}

.introduction {
    font-size: $font-size-m;
    line-height: $line-height-m;
    color: $color-base-white;
    text-align: left;
    font-weight: 300;
    margin: 0;
}

.logo {
    max-width: 100%;
    height: auto;
}
