    @import "styles/variables";
    @import "styles/mixins";

.links {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shareLinks {
    position: static;
    margin: 0;
    margin-left: auto;
    flex-direction: row;
}

.shareButton {
    background-color: transparent;
    border: 0;

    &:not(:last-child) {
        margin-right: $spacing-1;
    }
}
