    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding: $spacing-5 0;

    &:not(.categoryContainer) {
        @include not-desktop {
            padding-top: $spacing-5;
        }
    }
}

.loadMore {
    display: flex;
    margin: $spacing-8 auto 0;
    color: $color-base-white;
}
