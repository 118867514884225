    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include border-radius-lg;

    min-height: 362px;
    border: 1px solid $color-borders;
}

.thumbnail {
    height: 260px !important;
    margin: 0 auto $spacing-4;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
}

.title {
    @include heading-2;

    color: $color-base-800;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $color-base-500;
    }
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $spacing-4;
}

.button {
    @include text-small;

    font-weight: 700;
    padding: $spacing-2;
    border-radius: $border-radius-lg;
    color: $color-base-600;
}
